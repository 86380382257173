.main.column {
    padding: 0;
}
.floated-label-wrapper {
    position: relative;

    input[type=text],
    input[type=email],
    textarea { 
      box-shadow: none;
    }

    ::-moz-placeholder {
        opacity: 1;
    }
  
    label {
      background: $white;
      color: $primary-color;
      font-size: 0.75rem;
      font-weight: 600;
      opacity: 0;
      margin: 0 0.25rem;
      padding: 0 0.25rem;
      position: absolute;
      top: 2rem;
      transition: all 0.15s ease-in;
      z-index: -1;
  
      input[type=text],
      input[type=email],
      textarea {
        border-radius: 4px;
        font-size: 1.75em;
        padding: 0.5em;
      }
  
      &.show {
        opacity: 1;
        top: -0.8rem;
        z-index: 1;
        transition: all 0.15s ease-in;
        span {
            color: $secondary-color;
        }
      }
    }
    select.noSelect {
        color: #ccc;
        option {
            color: #000;
        }
    }

    ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        color: #ccc;
      }
      ::-moz-placeholder { /* Firefox 19+ */
        color: #ccc;
      }
      :-ms-input-placeholder { /* IE 10+ */
        color: #ccc;
      }
      :-moz-placeholder { /* Firefox 18- */
        color: #ccc;
      }
  }

  .otherTitle span {
    color: $secondary-color;
  }
  .fontNormal {
    font-size: 1rem;
  }
  
  
  